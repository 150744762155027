var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "outer" },
        [
          _c("TableBox", {
            attrs: {
              api: _vm.api,
              "api-data": _vm.apiData,
              "item-data": _vm.itemData,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "商家二维码",
            visible: _vm.dialogVisible,
            width: "30%",
            "before-close": () => (_vm.dialogVisible = false),
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "qr-code" },
            [
              _vm.qrCodeImg !== ""
                ? _c("el-image", {
                    staticStyle: { width: "100%", height: "100%" },
                    attrs: { src: _vm.qrCodeImg, fit: "fill" },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }