<template>
  <div class="app-container">
    <div class="outer">
      <TableBox
        :api="api"
        :api-data="apiData"
        :item-data="itemData"
      />
    </div>
    <!--二维码弹窗-->
    <el-dialog
      title="商家二维码"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="() => dialogVisible = false"
    >
      <div class="qr-code">
        <el-image
          v-if="qrCodeImg !== ''"
          style="width: 100%; height: 100%"
          :src="qrCodeImg"
          fit="fill"
        />
      </div>
      <!-- <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button type="primary">下 载</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
import TableBox from '@/components/common/table'
export default {
  name: 'Supplier',
  components: {
    TableBox
  },
  data() {
    return {
      isRead: 1, // 权限
      id: '', // 供应商ID - 搜索
      api: '',
      apiData: { id: localStorage.getItem('admin_type_value') || undefined },
      itemData: [
        { prop: 'suppliers_id', label: 'ID', width: '100' },
        { prop: 'suppliers_name', label: '供应商', width: '200' },
        { prop: 'site_name', label: '所属展馆', width: '150' },
        // { prop: 'suppliers_address', label: '退货地址' },
        { prop: 'suppliers_phone', label: '联系方式', width: '200' },
        { prop: 'status_name', label: '入驻状态', width: '120' },
        { prop: 'create_at', label: '入驻时间', width: '200' },
        { prop: 'store_name', label: '店铺名称' },
        { prop: 'suppliers_store_type_string', label: '店铺类型' },
        {
          prop: 'store_image_url',
          width: '160',
          label: '店铺头像',
          render(h, params) {
            return h('img', {
              attrs: {
                src: params.row.store_image_url,
                style: 'max-width: 90px;'
              }
            })
          }
        }
      ],
      // 商家二维码
      qrCodeImg: '',
      dialogVisible: false
    }
  },
  mounted() {
    this.isRead = parseInt(sessionStorage.getItem('is_read'), 10)
    this.api = this.$api.supplier.getlist
    if (this.isRead === 0) {
      const now = this
      const arr = [
        {
          prop: '',
          label: '操作',
          width: '100',
          fixed: 'right',
          render(h, params) {
            const arr = [{
              name: '编辑',
              class: 'table-controller',
              doEvent: () => now.edit(params.row['suppliers_id'])
            }, {
              name: '店铺信息',
              class: 'other-controller',
              doEvent: () => now.storeEdit(params.row['suppliers_id'], params.row['store_name'], params.row['store_image_url'], params.row['store_image_id'])
            }, {
              name: '查看二维码',
              class: 'table-controller',
              doEvent: () => now.readQrCode(params.row)
            }]
            return h('div', arr.map((item, index) => {
              return h('p', {
                domProps: {
                  innerHTML: item.name
                },
                attrs: {
                  class: item.class
                },
                on: {
                  click: item.doEvent
                }
              })
            }))
          }
        }
      ]
      this.itemData = [ ...this.itemData, ...arr ]
    }
  },
  methods: {
    edit(id) {
      this.$router.push({
        path: '/store/editSupplier',
        query: {
          type: 'edit',
          id
        }
      })
    },
    storeEdit(id, name, img, imgId) {
      this.$router.push({
        path: '/store/editStore',
        query: {
          type: 'edit',
          id,
          name,
          img,
          imgId
        }
      })
    },
    // 查看二维码
    readQrCode(val) {
      const that = this
      that.dialogVisible = true
      const url = that.$api.goods.store_qrcode
      that.$axios.post(url, {
        suppliers_id: val.suppliers_id
      }).then(res => {
        if (res.error === 0) {
          that.qrCodeImg = _.get(res, 'data.url', '')
        } else {
          that.qrCodeImg = ''
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.qr-code {
  width: 200px;
  height: 200px;
}
</style>
